<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="900px"
    scrollable
    persistent
  >
    <ProjectIssueForm
      :project-issue="newProjectIssue"
      :errors="projectIssueValidationErrors"
      :client-view="$isClient()"
      :loading="loading"
      :project-id="$route.params.projectId"
      @clear:errors="CLEAR_PROJECT_ISSUE_VALIDATION_ERRORS"
      @save="onSave"
      @cancel="onCancel"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ProjectIssueForm from '@/components/forms/ProjectIssueForm';

export default {
  name: 'CreateProjectIssue',

  components: { ProjectIssueForm },

  computed: {
    ...mapState('projectIssues', [
      'newProjectIssue',
      'editedProjectIssue',
      'projectIssueValidationErrors',
    ]),

    loading() {
      const { loading } = this.$store.getters;
      return (
        loading['post:api/project-issues'] ||
        loading[`post:api/project-issues-image/${this.editedProjectIssue.id}`]
      );
    },
  },

  created() {
    this.SET_PROJECT_ISSUE_VALIDATION_ERRORS();
  },

  methods: {
    ...mapMutations('projectIssues', [
      'CLEAR_PROJECT_ISSUE_VALIDATION_ERRORS',
      'SET_NEW_PROJECT_ISSUE',
      'SET_PROJECT_ISSUE_VALIDATION_ERRORS',
    ]),
    ...mapActions('projectIssues', ['storeProjectIssue']),

    async onSave(issue) {
      await this.storeProjectIssue(issue);
      this.$router.push(this.$route.meta.getBackRoute(this.$route.params.projectId));
    },

    onCancel(issue) {
      this.SET_NEW_PROJECT_ISSUE(issue); // saves progress
      this.$router.push(this.$route.meta.getBackRoute(this.$route.params.projectId));
    },
  },
};
</script>

<style scoped></style>
